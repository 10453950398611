import {
    ref, Ref, computed, reactive
} from 'vue';
import {
    ListHeaderItem, ListContent,
    ListActionItem
} from '@/components/common/list';
import { office, accountType, community } from '@/data';
import router from '@/router';
import remove from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';
import { getInitSortField } from '@/methods/sort-table';

// 落地呼叫选项
const enableLandLineOptions = [
    WordList.ProperAllTextSmartIndoorOrGuard,
    WordList.ProperAllTextPhoneIndoorOrGuard,
    WordList.ProperAllTextSmartPlusIndoorOrGuardBackup,
    WordList.ProperAllTextIndoorOrGuardSmartPlusBackup,
    WordList.ProperAllTextIndoorOrGuardPhoneBackup,
    WordList.ProperAllTextIndoorOrGuardSmartPlusPhone
];

const unableLandLineCallType = [
    WordList.ProperAllTextSmartIndoorOrGuard,
    '',
    '',
    WordList.ProperAllTextIndoorOrGuardSmartPlusBackup
];

const defaultCallType = [
    WordList.DefaultCallType
];

// 管理个人设备
const sipIPCallOptions = [
    WordList.ProperAllTextSIPCall,
    WordList.ProperAllTextIPCall
];

// 用户的呼叫权限
const accessOptions = [
    WordList.ProperAllTextOff,
    WordList.ProperAllTextOn
];

// 详情label相关
const peopleInfo = ref([{
    name: 'Name',
    title: WordList.TabelPersonUserInHtmlName,
    content: '',
    role: ''
}, {
    name: 'EmployeeID',
    title: WordList.ProperAllTextID,
    content: ''
}, {
    name: 'Account',
    title: WordList.ProperAllTextSIPNumber,
    content: ''
}, {
    name: 'Email',
    title: WordList.TabelPropertyManageEmail,
    content: ''
}, {
    name: 'MobileNumber',
    title: WordList.ProperAllTextMobileNumber,
    content: ''
}, {
    name: 'Phone',
    title: WordList.ProperAllTextLandline,
    content: ''
}, {
    name: 'CallType',
    title: WordList.ProperAllTextCallType,
    content: '',
    options: defaultCallType
}, {
    name: 'EnableIpDirect',
    title: WordList.ProperAllTextSipIpCall,
    content: '',
    options: sipIPCallOptions
}, {
    name: 'HasIntercomAccess',
    title: WordList.ProperAllTextIntercomAccess,
    content: '',
    options: accessOptions
}, {
    name: 'ActiveName',
    title: WordList.RDeviceListTanleActive,
    content: ''
}, {
    name: 'InitializationName',
    title: WordList.ProperAllTextAppStatus,
    content: ''
}]);

// 用户名下的设备
const devices: Ref< Array <ListContent> > = ref([]);
// 用户详情
const userDetailFormData: Ref<accountType.InstallerOfficeUserInfo> = ref({
    Name: '',
    EmployeeID: '',
    Account: '',
    Email: '',
    MobileNumber: '',
    Phone: '',
    CallType: '',
    EnableIpDirect: '',
    HasIntercomAccess: '',
    ActiveName: '',
    InitializationName: '',
    Role: '',
    Identity: '',
    Floor: []
});

const deviceHeader: Array< ListHeaderItem > = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation,
    headerType: 'customize'
}, {
    name: 'Type',
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
    type: 'customize'
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.ProperAllTextBindTime,
    headerType: 'customize'
}];

const updateList = ref(0);
const getInitData = () => {
    const userId = computed(() => router.currentRoute.value.query.id);
    const getUserInfo = () => {
        office.getOfficeUserInfo(String(userId.value), (res: {
            data: {
                details: accountType.InstallerOfficeUserInfo;
            };
        }) => {
            const { details } = res.data;
            peopleInfo.value.forEach((item, index) => {
                const tempItem = { ...item };
                const key = tempItem.name as keyof accountType.InstallerOfficeUserInfo;
                if (tempItem.options) {
                    // 获取calltype、enableipdirect信息
                    if (item.name === 'CallType') {
                        if (String(details.HasIntercomAccess) === '1') {
                            tempItem.content = community.IsEnableLandline.value === 1
                                ? enableLandLineOptions[Number(details[key])] : unableLandLineCallType[Number(details[key])];
                        } else {
                            tempItem.content = defaultCallType[Number(details[key])];
                        }
                    } else {
                        tempItem.content = tempItem.options[Number(details[key])];
                    }
                } else if (key === 'Name') {
                    // 保存角色
                    tempItem.content = details[key];
                    tempItem.role = details.Role;
                } else if (key !== 'ID' && key !== 'DepartmentID' && key !== 'Floor') {
                    tempItem.content = details[key] ? details[key] : '--';
                    details[key] = details[key] === null ? '' : details[key];
                }
                details[key] = String(details[key]);
                peopleInfo.value[index] = tempItem;
            });
            userDetailFormData.value = details;
            if (details.Floor) {
                userDetailFormData.value.Floor = details.Floor === 'all' ? ['all'] : (details.Floor as string).split(';');
            } else {
                userDetailFormData.value.Floor = [];
            }
            updateList.value += 1;
        });
    };
    getUserInfo();
    // 获取初始排序
    const initSortRes = getInitSortField('peopleInfoDevice');
    const deviceParams = reactive({
        ID: userId.value,
        SortField: initSortRes.sortField,
        Sort: initSortRes.sort
    });
    // 获取用户名下设备
    function getOwnerDevice() {
        office.getOwnerDeviceList({ ...deviceParams }, (res: {
            data: Array< ListContent >;
        }) => {
            devices.value = res.data;
            updateList.value += 1;
        });
    }
    getOwnerDevice();

    const deviceAction: ListActionItem[] = [{
        type: 'info2',
        event: 'info',
        class: 'el-icon-my-info info-icon',
        title: WordList.PersonuserInfo
    }, {
        type: 'edit2',
        event: 'edit',
        class: 'el-icon-my-modify modify-icon',
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.TabelDeleteText, () => {
                HttpRequest.post('v3/web/office/device/delete', {
                    ID: data.ID
                }, () => {
                    getOwnerDevice();
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        title: WordList.TabelConfigInHtmlDelete
    }];
    return {
        devices,
        deviceHeader,
        updateList,
        userDetailFormData,
        getUserInfo,
        deviceAction,
        deviceParams,
        getOwnerDevice
    };
};

interface UserDeviceType {
    Type: string;
    MAC: string;
    Location: string;
    NetGroupNumber: string;
    StairShow: string;
    NodeID: string;
    isPublic: boolean;
    Relay: string;
    RelayName1: string;
    RelayName2: string;
    RelayName3: string;
    ArmingFunction: boolean | string;
    Build: string;
}

export default null;
export {
    peopleInfo,
    getInitData,
    UserDeviceType
};