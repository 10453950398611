
import {
    defineComponent, ref, Ref, computed
} from 'vue';
import { account, device, community } from '@/data';
import { list } from '@/components/common/list';
import router, { communityBaseRouter, communitySubRouter } from '@/router';
import addButton from '@/components/common/add-button/index.vue';
import deviceSetDialog from '@/components/view/installer/device-set-dialog';
import peopleSetDialog from '@/components/view/office/people-set-dialog';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import { updateSortField, getSortImg } from '@/methods/sort-table';
import {
    peopleInfo, getInitData, UserDeviceType
} from './office-people-info';

export default defineComponent({
    components: {
        list,
        deviceSetDialog,
        addButton,
        peopleSetDialog,
        deviceIcon,
        deviceStatus
    },
    setup() {
        const {
            devices,
            deviceHeader,
            updateList,
            userDetailFormData,
            getUserInfo,
            deviceAction,
            getOwnerDevice,
            deviceParams
        } = getInitData();

        const enableLandLine = community.IsEnableLandline;

        const userId = computed(() => router.currentRoute.value.query.id);
        const departmentId = computed(() => router.currentRoute.value.query.DepartmentID);
        // 编辑成员信息
        const isShowPeopleDialog = ref(false);
        const editPeople = () => {
            userDetailFormData.value.ID = String(userId.value);
            isShowPeopleDialog.value = true;
        };
        // 新增设备
        const isShowDeviceDialog = ref(false);
        const operaType: Ref< OperateType > = ref('add');
        const initDeviceData: Ref<UserDeviceType> = ref({} as UserDeviceType);
        const addDevice = () => {
            initDeviceData.value = {
                Type: device.indoor,
                MAC: '',
                Location: '',
                NetGroupNumber: '0',
                StairShow: '1',
                NodeID: userId.value as string,
                isPublic: false,
                Relay: '',
                RelayName1: '',
                RelayName2: '',
                RelayName3: '',
                ArmingFunction: false,
                Build: departmentId.value as string
            };
            operaType.value = 'add';
            isShowDeviceDialog.value = true;
        };
        // 查看设备详情，跳转详情内页
        const goToDeviceInfo = (item: object & {
            Owner: string;
            Name: string;
        }) => {
            const detail = item;
            detail.Owner = item.Name;
            localStorage.setItem('detail', JSON.stringify(detail));
            router.push(`/${communityBaseRouter}/${communitySubRouter.devicedetail}?type=office`);
        };
        // 编辑设备信息
        const editDevice = (info: UserDeviceType) => {
            initDeviceData.value = info;
            operaType.value = 'edit';
            isShowDeviceDialog.value = true;
            initDeviceData.value.ArmingFunction = info.ArmingFunction === '1';
            initDeviceData.value.NodeID = userId.value as string;
        };

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            deviceParams.Sort = sort;
            deviceParams.SortField = sortField;
            getOwnerDevice();
        }
        return {
            peopleInfo,
            account,
            devices,
            deviceHeader,
            updateList,
            isShowDeviceDialog,
            operaType,
            initDeviceData,
            isShowPeopleDialog,
            userDetailFormData,
            editPeople,
            addDevice,
            goToDeviceInfo,
            editDevice,
            getUserInfo,
            deviceAction,
            enableLandLine,
            getSortImg,
            getSortRes,
            getOwnerDevice
        };
    }
});
